import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const undisbursedFundsRestsStore = {
    namespaced: true,
    state: {
        undisbursed_funds_rests: [],
        loading: false,
        undisbursed_funds_rest: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.undisbursed_funds_rests.length > 0 ? state.undisbursed_funds_rests[0].total : 0
        },
        undisbursed_funds_rests (state) {
            return state.undisbursed_funds_rests
        },
        undisbursed_funds_rest (state) {
            return state.undisbursed_funds_rest
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateUndisbursedFundsRest(state, undisbursed_funds_rest) {
            state.undisbursed_funds_rest = undisbursed_funds_rest
        },
        updateUndisbursedFundsRests(state, undisbursed_funds_rests) {
            state.undisbursed_funds_rests = undisbursed_funds_rests
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadUndisbursedFundsRests({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/undisbursed_funds_rests.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateUndisbursedFundsRests', response.data))
            commit('loadingOff')
        },
        async loadUndisbursedFundsRest({ commit }, itemId) {
            axios.get(`/api/v1/undisbursed_funds_rests/${itemId}.json`)
                .then(response => commit('updateUndisbursedFundsRest', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createUndisbursedFundsRest({ commit }, formData) {
            axios.post('/api/v1/undisbursed_funds_rests.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateUndisbursedFundsRest({ commit, state }, formData) {
            axios.put(`/api/v1/undisbursed_funds_rests/${state.undisbursed_funds_rest.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyUndisbursedFundsRest({ commit }, itemId) {
            axios.delete(`/api/v1/undisbursed_funds_rests/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default undisbursedFundsRestsStore


