import axios from 'axios';
import store from '../store'

export default function setup() {
    axios.interceptors.request.use(function (config) {
        store.commit('global/updateLoader', true)
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

// Add a response interceptor
    axios.interceptors.response.use(function (response) {
        store.commit('global/updateLoader', false)
        return response;
    }, function (error) {
        // Do something with response error
        return Promise.reject(error);
    });
}