import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const customerProgramsStore = {
    namespaced: true,
    state: {
        customer_programs: [],
        loading: false,
        customer_program: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.statecustomers.length > 0 ? state.statecustomers[0].total : 0
        },
        customer_programs (state) {
            return state.customer_programs
        },
        customer_program (state) {
            return state.customer_program
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateCustomerProgram(state, customer_program) {
            state.customer_program = customer_program
        },
        updateCustomerPrograms(state, customer_programs) {
            state.customer_programs = customer_programs
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadAllCustomerPrograms({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/customer_programs.json`)
                .then(response => commit('updateCustomerPrograms', response.data))
            commit('loadingOff')
        },
        async loadCustomerPrograms({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/customer_programs.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateCustomerPrograms', response.data))
            commit('loadingOff')
        },
        async loadCustomerProgram({ commit }, itemId) {
            axios.get(`/api/v1/customer_programs/${itemId}.json`)
                .then(response => commit('updateCustomerProgram', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createCustomerProgram({ commit }, formData) {
            axios.post('/api/v1/customer_programs.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateCustomerProgram({ commit, state }, formData) {
            axios.put(`/api/v1/customer_programs/${state.customer_program.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyCustomerProgram({ commit }, itemId) {
            axios.delete(`/api/v1/customer_programs/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default customerProgramsStore


