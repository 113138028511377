import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const reportsStore = {
    namespaced: true,
    state: {
        reports: [],
        report: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,

    },
    mutations: {
        updateField,


    },
    actions: {

    }
}

export default reportsStore


