import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const niokrsStore = {
    namespaced: true,
    state: {
        niokrs: [],
        loading: false,
        niokr: {
        },
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.niokrs.length > 0 ? state.niokrs[0].total : 0
        },
        niokrs(state) {
            return state.niokrs
        },
        niokr(state) {
            return state.niokr
        },
        opts (state) {
            return state.opts
        },
        loading(state) {
            return state.loading
        },
        pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateNiokr(state, niokr) {
            state.niokr = niokr
        },
        updateNiokrs(state, niokrs) {
            state.niokrs = niokrs
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadNiokrs({commit, state}) {
            commit('loadingOn')
            axios.get(`/api/v1/niokrs.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateNiokrs', response.data))
            commit('loadingOff')
        },
        async loadNiokr({commit}, itemId) {
            axios.get(`/api/v1/niokrs/${itemId}.json`)
                .then(response => commit('updateNiokr', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createNiokr({commit}, formData) {
            axios.post('/api/v1/niokrs.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async updateNiokr({commit, state}, formData) {
            axios.put(`/api/v1/niokrs/${state.niokr.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyNiokr({commit}, itemId) {
            axios.delete(`/api/v1/niokrs/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default niokrsStore


