import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const allowedDistrictsStore = {
    namespaced: true,
    state: {
        allowed_districts: [],
        loading: false,
        allowed_district: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.allowed_districts.length > 0 ? state.allowed_districts[0].total : 0
        },
        allowed_districts (state) {
            return state.allowed_districts
        },
        allowed_district (state) {
            return state.allowed_district
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateAllowedDistrict(state, allowed_district) {
            state.allowed_district = allowed_district
        },
        updateAllowedDistricts(state, allowed_districts) {
            state.allowed_districts = allowed_districts
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadAllowedDistricts({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/allowed_districts.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateAllowedDistricts', response.data))
            commit('loadingOff')
        },
        async loadAllowedDistrict({ commit }, itemId) {
            axios.get(`/api/v1/allowed_districts/${itemId}.json`)
                .then(response => commit('updateAllowedDistrict', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createAllowedDistrict({ commit }, formData) {
            axios.post('/api/v1/allowed_districts.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateAllowedDistrict({ commit, state }, formData) {
            axios.put(`/api/v1/allowed_districts/${state.allowed_district.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyAllowedDistrict({ commit }, itemId) {
            axios.delete(`/api/v1/allowed_districts/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default allowedDistrictsStore


