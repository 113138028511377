<template>
    <v-col
            cols="12"
            sm="8"
            md="5"
            lg="4"
    >
        <v-row class="mb-4">

            <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="text-center text-md-right"
            >
                <v-avatar tile size="80">
                    <img :src="require('images/logo.png')" alt="Aries">
                </v-avatar>
            </v-col>
            <v-col
                    cols="12"
                    sm="6"
                    md="8"
                    class="text-center text-md-left"
            >
                <h1 class="text--primary">Восстановление пароля</h1>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-toolbar
                    color="primary"
                    dark
                    flat
                    dense
            >
                <v-toolbar-title>
                    <v-btn icon  :href="formUrl.restore_path">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                </v-toolbar-title>
                <v-spacer />
            </v-toolbar>

            <v-card-text>
                <v-alert
                        text
                        outlined
                        color="deep-orange"
                        dismissible
                        type="warning"
                        v-if="formUrl.flash.length > 0"
                        v-for="message in formUrl.flash"
                >
                    {{message[1]}}
                </v-alert>
                <v-text-field
                        id="user_email"
                        label="E-mail"
                        name="email[login]"
                        prepend-icon="mdi-account"
                        type="text"
                        outlined
                        dense
                />



            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn block outlined color="primary" type="submit">Выслать мне инструкцию восстановления</v-btn>
            </v-card-actions>

        </v-card>
        <v-card dark class="my-4 primary">
            <v-card-text>
                <v-btn dark text color small :href="formUrl.restore_path">Войти</v-btn>
            </v-card-text>

        </v-card>

    </v-col>
    
</template>

<script>
    export default {
        name: "devise-new-password",
        data: () => ({
            formUrl: JSON.parse(document.getElementById('vue-data').getAttribute('data'))
        }),
    }
</script>

<style scoped>

</style>