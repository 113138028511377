// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix/dist/trix.css")
require("../stylesheets/actiontext.scss")
require("trix")
require("@rails/actiontext")

import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import Axios from 'axios'
import DeviseLayout from '../components/application/devise'
import vuetify from '../plugins/vuetify_plugin'
import interceptorsSetup from '../plugins/interceptors'

interceptorsSetup()

Vue.use(VueTheMask)
Vue.use(Axios)

document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("devise_form").appendChild(document.createElement('main'));
    const app = new Vue({
        el,
        vuetify,
        render: h => h(DeviseLayout),
    })
});