import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const omsuRequisitesStore = {
    namespaced: true,
    state: {
        omsu_requisites: [],
        loading: false,
        omsu_requisite: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.omsu_requisites.length > 0 ? state.omsu_requisites[0].total : 0
        },
        omsu_requisites (state) {
            return state.omsu_requisites
        },
        omsu_requisite (state) {
            return state.omsu_requisite
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateOmsuRequisite(state, omsu_requisite) {
            state.omsu_requisite = omsu_requisite
        },
        updateOmsuRequisites(state, omsu_requisites) {
            state.omsu_requisites = omsu_requisites
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadOmsuRequisites({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/omsu_requisites.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateOmsuRequisites', response.data))
            commit('loadingOff')
        },
        async loadOmsuRequisite({ commit }, itemId) {
            axios.get(`/api/v1/omsu_requisites/${itemId}.json`)
                .then(response => commit('updateOmsuRequisite', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createOmsuRequisite({ commit }, formData) {
            axios.post('/api/v1/omsu_requisites.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateOmsuRequisite({ commit, state }, formData) {
            axios.put(`/api/v1/omsu_requisites/${state.omsu_requisite.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyOmsuRequisite({ commit }, itemId) {
            axios.delete(`/api/v1/omsu_requisites/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default omsuRequisitesStore