import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const globalStore = {
    namespaced: true,
    state: {
        loading: false
    },
    getters: {
        getField,
        loading: (state) => {
            return state.loading
        }
    },
    mutations: {
        updateField,
        updateLoader(state, value) {
            state.loading = value
        }

    },
    actions: {

    }
}

export default globalStore


