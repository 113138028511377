import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const districtsStore = {
    namespaced: true,
    state: {
        districts: [],
        district: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.districts.length > 0 ? state.districts[0].total : 0
        },
        districts (state) {
            return state.districts
        },
        district (state) {
            return state.district
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateDistrict(state, district) {
            state.district = district
        },
        updateDistricts(state, districts) {
            state.districts = districts
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }
    },
    actions: {
        async loadDistricts({ commit, state }) {
            axios.get(`/api/v1/districts.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateDistricts', response.data))
        },
        async loadDistrict({ commit }, itemId) {
            axios.get(`/api/v1/districts/${itemId}.json`)
                .then(response => commit('updateDistrict', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createDistrict({ commit }, formData) {
            axios.post('/api/v1/districts.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateDistrict({ commit, state }, formData) {
            axios.put(`/api/v1/districts/${state.district.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyDistrict({ commit }, itemId) {
            axios.delete(`/api/v1/districts/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default districtsStore


