import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import globalStore from './stores/global_store'
import fcpsStore from './stores/fcps_store'
import dcpsStore from './stores/dcps_store'
import mcpsStore from './stores/mcps_store'
import building_objectsStore from './stores/building_objects_store'
import statecustomersStore from './stores/statecustomers_store'
import buildercustomersStore from './stores/buildercustomers_store'
import indexDeflatorsStore from './stores/index_deflators_store'
import usersStore from './stores/users_store'
import periodsStore from './stores/periods_store'
import contractorsStore from './stores/contractors_store'
import programsStore from './stores/programs_store'
import districtsStore from './stores/districts_store'
import achievedResultsStore from './stores/achieved_results_store'
import plannedResultsStore from './stores/planned_results_store'
import financingPeriodsStore from './stores/financing_periods_store'
import niokrsStore from './stores/niokrs_store'
import otherCostsStore from "./stores/other_costs_store";
import otherInvestmentsStore from "./stores/other_investments_store";
import agreementInfosStore from "./stores/agreement_infos_store";
import allowedDistrictsStore from "./stores/allowed_districts_store";
import allowedProgramsStore from "./stores/allowed_programs_store";
import customerBuildingObjectsStore from "./stores/customer_building_objects_store";
import mainStateExpertisesStore from "./stores/main_state_expertises_store";
import omsuRequisitesStore from "./stores/omsu_requisites_store";
import plannedFinancingVolumesStore from "./stores/planned_financing_volumes_store";
import preparednessLevelsStore from "./stores/preparedness_levels_store";
import proceduralStagesStore from "./stores/procedural_stages_store";
import reportsStore from "./stores/reports_store";
import undisbursedFundsRestsStore from "./stores/undisbursed_funds_rests_store";
import customerProgramsStore from "./stores/customer_programs_store";
import eventCommentsStore from "./stores/event_comments_store";

const store = new Vuex.Store({
    modules: {
        global: globalStore,
        fcps: fcpsStore,
        dcps: dcpsStore,
        mcps: mcpsStore,
        building_objects: building_objectsStore,
        statecustomers: statecustomersStore,
        buildercustomers: buildercustomersStore,
        index_deflators: indexDeflatorsStore,
        contractors: contractorsStore,
        periods: periodsStore,
        users: usersStore,
        programs: programsStore,
        districts: districtsStore,
        achieved_results: achievedResultsStore,
        planned_results: plannedResultsStore,
        financing_periods: financingPeriodsStore,
        niokrs: niokrsStore,
        other_costs: otherCostsStore,
        other_investments: otherInvestmentsStore,
        agreement_infos: agreementInfosStore,
        allowed_districts: allowedDistrictsStore,
        allowed_programs: allowedProgramsStore,
        customer_building_objects: customerBuildingObjectsStore,
        main_state_expertises: mainStateExpertisesStore,
        omsu_requisites: omsuRequisitesStore,
        planned_financing_volumes: plannedFinancingVolumesStore,
        preparedness_levels: preparednessLevelsStore,
        procedural_stages: proceduralStagesStore,
        reports: reportsStore,
        undisbursed_funds_rests: undisbursedFundsRestsStore,
        customer_programs: customerProgramsStore,
        event_comments: eventCommentsStore,
    }
})

export default store