import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const mainStateExpertisesStore = {
    namespaced: true,
    state: {
        main_state_expertises: [],
        loading: false,
        main_state_expertise: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.main_state_expertises.length > 0 ? state.main_state_expertises[0].total : 0
        },
        main_state_expertises (state) {
            return state.main_state_expertises
        },
        main_state_expertise (state) {
            return state.main_state_expertise
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateMainStateExpertise(state, main_state_expertise) {
            state.main_state_expertise = main_state_expertise
        },
        updateMainStateExpertises(state, main_state_expertises) {
            state.main_state_expertises = main_state_expertises
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadMainStateExpertises({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/main_state_expertises.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateMainStateExpertises', response.data))
            commit('loadingOff')
        },
        async loadMainStateExpertise({ commit }, itemId) {
            axios.get(`/api/v1/main_state_expertises/${itemId}.json`)
                .then(response => commit('updateMainStateExpertise', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createMainStateExpertise({ commit }, formData) {
            axios.post('/api/v1/main_state_expertises.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateMainStateExpertise({ commit, state }, formData) {
            axios.put(`/api/v1/main_state_expertises/${state.main_state_expertise.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyMainStateExpertise({ commit }, itemId) {
            axios.delete(`/api/v1/main_state_expertises/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default mainStateExpertisesStore


