import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const usersStore = {
    namespaced: true,
    state: {
        users: [],
        user: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.users.length > 0 ? state.users[0].total : 0
        },
        users (state) {
            return state.users
        },
        user (state) {
            return state.user
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateUser(state, user) {
            state.user = user
        },
        updateUsers(state, users) {
            state.users = users
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }
    },
    actions: {
        async loadUsers({ commit, state }) {
            axios.get(`/api/v1/users.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateUsers', response.data))
        },
        async loadUser({ commit }, userId) {
            axios.get(`/api/v1/users/${userId}.json`)
                .then(response => commit('updateUser', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createUser({ commit }, formData) {
            axios.post('/api/v1/users.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateUser({ commit, state }, formData) {
            axios.put(`/api/v1/users/${state.user.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyUser({ commit }, userId) {
            axios.delete(`/api/v1/users/${userId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default usersStore


