import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const otherCostsStore = {
    namespaced: true,
    state: {
        other_costs: [],
        loading: false,
        other_cost: {
        },
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.other_costs.length > 0 ? state.other_costs[0].total : 0
        },
        other_costs(state) {
            return state.other_costs
        },
        other_cost(state) {
            return state.other_cost
        },
        opts (state) {
            return state.opts
        },
        loading(state) {
            return state.loading
        },
        pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateOtherCost(state, other_cost) {
            state.other_cost = other_cost
        },
        updateOtherCosts(state, other_costs) {
            state.other_costs = other_costs
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadOtherCosts({commit, state}) {
            commit('loadingOn')
            axios.get(`/api/v1/other_costs.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateOtherCosts', response.data))
            commit('loadingOff')
        },
        async loadOtherCost({commit}, itemId) {
            axios.get(`/api/v1/other_costs/${itemId}.json`)
                .then(response => commit('updateOtherCost', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createOtherCost({commit}, formData) {
            axios.post('/api/v1/other_costs.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async updateOtherCost({commit, state}, formData) {
            axios.put(`/api/v1/other_costs/${state.other_cost.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyOtherCost({commit}, itemId) {
            axios.delete(`/api/v1/other_costs/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default otherCostsStore


