import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const fcpsStore = {
    namespaced: true,
    state: {
        fcps: [],
        loading: false,
        fcp: {
            building_objects: [],
            niokrs: [],
            other_costs: [],
        },
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.fcps.length > 0 ? state.fcps[0].total : 0
        },
        fcps(state) {
            return state.fcps
        },
        fcp(state) {
            return state.fcp
        },
        opts (state) {
            return state.opts
        },
        loading(state) {
            return state.loading
        },
        pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateFcp(state, fcp) {
            state.fcp = fcp
        },
        updateFcps(state, fcps) {
            state.fcps = fcps
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadFcps({commit, state}) {
            commit('loadingOn')
            axios.get(`/api/v1/fcps.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateFcps', response.data))
            commit('loadingOff')
        },
        async loadFcp({commit}, itemId) {
            axios.get(`/api/v1/fcps/${itemId}.json`)
                .then(response => commit('updateFcp', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createFcp({commit}, formData) {
            axios.post('/api/v1/fcps.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async updateFcp({commit, state}, formData) {
            axios.put(`/api/v1/fcps/${state.fcp.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyFcp({commit}, itemId) {
            axios.delete(`/api/v1/fcps/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default fcpsStore


