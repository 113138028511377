import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const allowedProgramsStore = {
    namespaced: true,
    state: {
        allowed_programs: [],
        loading: false,
        allowed_program: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.allowed_programs.length > 0 ? state.allowed_programs[0].total : 0
        },
        allowed_programs (state) {
            return state.allowed_programs
        },
        allowed_program (state) {
            return state.allowed_program
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateAllowedProgram(state, allowed_program) {
            state.allowed_program = allowed_program
        },
        updateAllowedPrograms(state, allowed_programs) {
            state.allowed_programs = allowed_programs
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadAllowedPrograms({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/allowed_programs.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateAllowedPrograms', response.data))
            commit('loadingOff')
        },
        async loadAllowedProgram({ commit }, itemId) {
            axios.get(`/api/v1/allowed_programs/${itemId}.json`)
                .then(response => commit('updateAllowedProgram', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createAllowedProgram({ commit }, formData) {
            axios.post('/api/v1/allowed_programs.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateAllowedProgram({ commit, state }, formData) {
            axios.put(`/api/v1/allowed_programs/${state.allowed_program.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyAllowedProgram({ commit }, itemId) {
            axios.delete(`/api/v1/allowed_programs/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default allowedProgramsStore


