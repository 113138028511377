import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const programsStore = {
    namespaced: true,
    state: {
        programs: [],
        program: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.programs.length > 0 ? state.programs[0].total : 0
        },
        programs (state) {
            return state.programs
        },
        program (state) {
            return state.program
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateProgram(state, program) {
            state.program = program
        },
        updatePrograms(state, programs) {
            state.programs = programs
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadPrograms({ commit, state }) {
            axios.get(`/api/v1/programs.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updatePrograms', response.data))
        },
        async loadProgram({ commit }, userId) {
            axios.get(`/api/v1/programs/${userId}.json`)
                .then(response => commit('updateProgram', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createProgram({ commit }, formData) {
            axios.post('/api/v1/programs.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateProgram({ commit, state }, formData) {
            axios.put(`/api/v1/programs/${state.user.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyProgram({ commit }, itemId) {
            axios.delete(`/api/v1/programs/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default programsStore


