import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const buildercustomersStore = {
    namespaced: true,
    state: {
        loading: false,
        buildercustomers: [],
        buildercustomer: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.buildercustomers.length > 0 ? state.buildercustomers[0].total : 0
        },
        buildercustomers (state) {
            return state.buildercustomers
        },
        buildercustomer (state) {
            return state.buildercustomer
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateBuildercustomer(state, buildercustomer) {
            state.buildercustomer = buildercustomer
        },
        updateBuildercustomers(state, buildercustomers) {
            state.buildercustomers = buildercustomers
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadBuildercustomers({ commit, state }) {
            axios.get(`/api/v1/buildercustomers.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateBuildercustomers', response.data))
        },
        async loadBuildercustomer({ commit }, itemId) {
            axios.get(`/api/v1/buildercustomers/${itemId}.json`)
                .then(response => commit('updateBuildercustomer', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createBuildercustomer({ commit }, formData) {
            axios.post('/api/v1/buildercustomers.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateBuildercustomer({ commit, state }, formData) {
            axios.put(`/api/v1/buildercustomers/${state.buildercustomer.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyBuildercustomer({ commit }, itemId) {
            axios.delete(`/api/v1/buildercustomers/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default buildercustomersStore


