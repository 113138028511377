import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const agreementInfosStore = {
    namespaced: true,
    state: {
        agreement_infos: [],
        loading: false,
        agreement_info: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.agreement_infos.length > 0 ? state.agreement_infos[0].total : 0
        },
        agreement_infos (state) {
            return state.agreement_infos
        },
        agreement_info (state) {
            return state.agreement_info
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateAgreementInfo(state, agreement_info) {
            state.agreement_info = agreement_info
        },
        updateAgreementInfos(state, agreement_infos) {
            state.agreement_infos = agreement_infos
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadAgreementInfos({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/agreement_infos.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateAgreementInfos', response.data))
            commit('loadingOff')
        },
        async loadAgreementInfo({ commit }, itemId) {
            axios.get(`/api/v1/agreement_infos/${itemId}.json`)
                .then(response => commit('updateAgreementInfo', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createAgreementInfo({ commit }, formData) {
            axios.post('/api/v1/agreement_infos.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateAgreementInfo({ commit, state }, formData) {
            axios.put(`/api/v1/agreement_infos/${state.agreement_info.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyAgreementInfo({ commit }, itemId) {
            axios.delete(`/api/v1/agreement_infos/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default agreementInfosStore


