import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const building_objectsStore = {
    namespaced: true,
    state: {
        building_objects: [],
        loading: false,
        building_object: {},
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.building_objects.length > 0 ? state.building_objects[0].total : 0
        },
        building_objects (state) {
            return state.building_objects
        },
        building_object (state) {
            return state.building_object
        },
        opts (state) {
            return state.opts
        },
        loading (state) {
            return state.loading
        },
        pagination (state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateBuildingObject(state, building_object) {
            state.building_object = building_object
        },
        updateBuildingObjects(state, building_objects) {
            state.building_objects = building_objects
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadBuildingObjects({ commit, state }) {
            commit('loadingOn')
            axios.get(`/api/v1/building_objects.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateBuildingObjects', response.data))
            commit('loadingOff')
        },
        async loadBuildingObject({ commit }, itemId) {
            axios.get(`/api/v1/building_objects/${itemId}.json`)
                .then(response => commit('updateBuildingObject', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createBuildingObject({ commit }, formData) {
            axios.post('/api/v1/building_objects.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function(error){
                    commit('updateErrors', error.response)
                })
        },
        async updateBuildingObject({ commit, state }, formData) {
            axios.put(`/api/v1/building_objects/${state.building_object.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyBuildingObject({ commit }, itemId) {
            axios.delete(`/api/v1/building_objects/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default building_objectsStore


