import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'

const otherInvestmentsStore = {
    namespaced: true,
    state: {
        other_investments: [],
        loading: false,
        other_investment: {
        },
        opts: {
            actions: true,
            parentData: false
        },
        errors: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        }
    },
    getters: {
        getField,
        totalItems: (state) => {
            return state.other_investments.length > 0 ? state.other_investments[0].total : 0
        },
        other_investments(state) {
            return state.other_investments
        },
        other_investment(state) {
            return state.other_investment
        },
        opts (state) {
            return state.opts
        },
        loading(state) {
            return state.loading
        },
        pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        updateField,
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateOtherInvestment(state, other_investment) {
            state.other_investment = other_investment
        },
        updateOtherInvestments(state, other_investments) {
            state.other_investments = other_investments
        },
        updateErrors(state, errors) {
            state.errors = errors
        },
        loadingOn(state) {
            state.loading = true
        },
        loadingOff(state) {
            state.loading = false
        },
        updateOpts(state, opts) {
            state.opts = opts
        }

    },
    actions: {
        async loadOtherInvestments({commit, state}) {
            commit('loadingOn')
            axios.get(`/api/v1/other_investments.json?page=${state.pagination.page}&per=${state.pagination.itemsPerPage}&sort=${state.pagination.sortBy}&descending=${state.pagination.sortDesc}`)
                .then(response => commit('updateOtherInvestments', response.data))
            commit('loadingOff')
        },
        async loadOtherInvestmentr({commit}, itemId) {
            axios.get(`/api/v1/other_investments/${itemId}.json`)
                .then(response => commit('updateOtherInvestment', response.data))
                .catch(errors => commit('updateErrors', errors))
        },
        async createOtherInvestment({commit}, formData) {
            axios.post('/api/v1/other_investments.json', formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async updateOtherInvestment({commit, state}, formData) {
            axios.put(`/api/v1/other_investments/${state.other_investment.id}.json`, formData)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    commit('updateErrors', error.response)
                })
        },
        async destroyOtherInvestment({commit}, itemId) {
            axios.delete(`/api/v1/other_investments/${itemId}.json`)
                .then(function (response) {
                    return 'success';
                })
        }
    }
}

export default otherInvestmentsStore


