<template>
  <div id="app">
    <v-app id="inspire">
      <v-content>
        <v-container
                class="fill-height"
                fluid

        >
          <v-row
                  align="center"
                  justify="center"
                  style="max-width: unset;"
          >
            <DeviseNewSession v-if="formUrl.action == 'new_session'"></DeviseNewSession>
            <DeviseNewPassword v-if="formUrl.action == 'new_password'"></DeviseNewPassword>
          </v-row>
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import DeviseNewSession from '../devise/session'
import DeviseNewPassword from '../devise/password'
export default {
  name: 'devise-layout',
  data: () => ({
      formUrl: JSON.parse(document.getElementById('vue-data').getAttribute('data'))
  }),

  components: {
    DeviseNewSession, DeviseNewPassword
  }
}
</script>

<style scoped>

</style>

